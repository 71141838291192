var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "partner-join section"
  }, [_c('div', [_c('h2', {
    staticClass: "light-theme-title text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.title")) + " ")]), _c('h4', {
    staticClass: "partner-join-subheader text-center subtitle-bodyLarge text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.subtitle")) + " ")]), _c('div', {
    staticClass: "partner-join-items container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
  }, [_c('div', {
    staticClass: "partner-join-icon"
  }, [_c('PartnerRegisterIcon')], 1), _c('div', {
    staticClass: "partner-join-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-mobile-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.register")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('partner.join-partner.register-text'))
    }
  })])]), _c('div', {
    staticClass: "partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
  }, [_c('div', {
    staticClass: "partner-join-icon"
  }, [_c('PartnerVerifyIcon')], 1), _c('div', {
    staticClass: "partner-join-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-mobile-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.verify")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.verify-text")) + " ")])])]), _c('div', {
    staticClass: "partner-join-item d-flex flex-column start align-items-center col-12 col-md-6 col-xl-3"
  }, [_c('div', {
    staticClass: "partner-join-icon"
  }, [_c('PartnerReferIcon')], 1), _c('div', {
    staticClass: "partner-join-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-mobile-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.refer")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500 ref"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.refer-text")) + " ")])])]), _c('div', {
    staticClass: "partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
  }, [_c('div', {
    staticClass: "partner-join-icon"
  }, [_c('PartnerEarnIcon')], 1), _c('div', {
    staticClass: "partner-join-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-mobile-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.earn")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500 earn"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.join-partner.earn-text")) + " ")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }