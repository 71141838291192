var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "partner-why section"
  }, [_c('h2', {
    staticClass: "text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.title")) + " ")]), _c('div', {
    staticClass: "partner-why-content d-flex justify-content-center justify-content-md-between align-items-center"
  }, [_c('div', {
    staticClass: "partner-why-items d-flex flex-column h-100"
  }, [_c('div', {
    staticClass: "partner-why-item d-flex align-items-md-center"
  }, [_c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('PartnerWhyCheckIcon')], 1), _c('div', {
    staticClass: "d-md-none"
  }, [_c('MobileCheckIcon')], 1), _c('div', {
    staticClass: "partner-why-text"
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.remuneration")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500",
    staticStyle: {
      "max-width": "380px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.remuneration-text")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.remuneration-text2")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("partner.why-partner.remuneration-text3")) + " ")])])]), _c('div', {
    staticClass: "partner-why-item d-flex align-items-md-center"
  }, [_c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('PartnerWhyCheckIcon')], 1), _c('div', {
    staticClass: "d-md-none"
  }, [_c('MobileCheckIcon')], 1), _c('div', {
    staticClass: "partner-why-text"
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.payout")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500",
    staticStyle: {
      "max-width": "380px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.payout-text")) + " ")])])]), _c('div', {
    staticClass: "partner-why-item d-flex align-items-md-center"
  }, [_c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('PartnerWhyCheckIcon')], 1), _c('div', {
    staticClass: "d-md-none"
  }, [_c('MobileCheckIcon')], 1), _c('div', {
    staticClass: "partner-why-text"
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.report")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500",
    staticStyle: {
      "max-width": "380px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.why-partner.report-text")) + " ")])])])]), _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('PartnerWhyIcon', {
    staticClass: "partner-why-icon"
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }