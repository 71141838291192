var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "46",
      "height": "46",
      "viewBox": "0 0 46 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.4154 30.4782V36.4079L23.1179 30.6746L24.1321 30.658C28.1645 30.5919 31.7935 30.1859 34.5071 29.7731C36.2196 29.5125 37.4095 28.3173 37.668 26.8166C38.0266 24.735 38.332 22.0977 38.332 19.1673C38.332 16.2369 38.0266 13.5996 37.668 11.518C37.4095 10.0173 36.2196 8.82214 34.5071 8.56157C31.5392 8.10999 27.4805 7.66732 22.9987 7.66732C18.5169 7.66732 14.4582 8.10999 11.4903 8.56157C9.7778 8.82214 8.58786 10.0173 8.32936 11.518C7.9708 13.5996 7.66536 16.2369 7.66536 19.1673C7.66536 22.0977 7.9708 24.735 8.32936 26.8166C8.55383 28.1198 9.46423 29.1643 10.7916 29.6057L13.4154 30.4782ZM4.55166 10.8673C5.10785 7.63833 7.67442 5.26473 10.9137 4.77186C14.0174 4.29961 18.2752 3.83398 22.9987 3.83398C27.7222 3.83398 31.98 4.29961 35.0837 4.77186C38.323 5.26473 40.8895 7.63833 41.4457 10.8673C41.8334 13.1178 42.1654 15.9766 42.1654 19.1673C42.1654 22.3581 41.8334 25.2169 41.4457 27.4673C40.8895 30.6963 38.323 33.0699 35.0837 33.5628C32.2443 33.9948 28.439 34.4213 24.1948 34.4908L12.4738 41.4169C11.1961 42.1719 9.58203 41.2509 9.58203 39.7668V33.2432C6.98794 32.3806 5.0291 30.2391 4.55166 27.4673C4.16402 25.2169 3.83203 22.3581 3.83203 19.1673C3.83203 15.9766 4.16402 13.1178 4.55166 10.8673Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.4167 13.416C12.3581 13.416 11.5 14.2741 11.5 15.3327C11.5 16.3912 12.3581 17.2493 13.4167 17.2493H32.5833C33.6419 17.2493 34.5 16.3912 34.5 15.3327C34.5 14.2741 33.6419 13.416 32.5833 13.416H13.4167ZM13.4167 21.0827C12.3581 21.0827 11.5 21.9408 11.5 22.9994C11.5 24.0579 12.3581 24.916 13.4167 24.916H21.0833C22.1419 24.916 23 24.0579 23 22.9994C23 21.9408 22.1419 21.0827 21.0833 21.0827H13.4167Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }