<template>
  <div class="partner-why section">
    <h2 class="text-center light-theme-title">
      {{ $t("partner.why-partner.title") }}
    </h2>

    <div
      class="partner-why-content d-flex justify-content-center justify-content-md-between align-items-center"
    >
      <div class="partner-why-items d-flex flex-column h-100">
        <div class="partner-why-item d-flex align-items-md-center">
          <div class="d-none d-md-block">
            <PartnerWhyCheckIcon />
          </div>
          <div class="d-md-none">
            <MobileCheckIcon />
          </div>

          <div class="partner-why-text">
            <h3 class="light-theme-title">
              {{ $t("partner.why-partner.remuneration") }}
            </h3>
            <p
              style="max-width: 380px"
              class="body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
            >
              {{ $t("partner.why-partner.remuneration-text") }}
              <span class="text-primary">
                {{ $t("partner.why-partner.remuneration-text2") }}
              </span>
              {{ $t("partner.why-partner.remuneration-text3") }}
            </p>
          </div>
        </div>

        <div class="partner-why-item d-flex align-items-md-center">
          <div class="d-none d-md-block">
            <PartnerWhyCheckIcon />
          </div>
          <div class="d-md-none">
            <MobileCheckIcon />
          </div>
          <div class="partner-why-text">
            <h3 class="light-theme-title">
              {{ $t("partner.why-partner.payout") }}
            </h3>
            <p
              style="max-width: 380px"
              class="body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
            >
              {{ $t("partner.why-partner.payout-text") }}
            </p>
          </div>
        </div>

        <div class="partner-why-item d-flex align-items-md-center">
          <div class="d-none d-md-block">
            <PartnerWhyCheckIcon />
          </div>
          <div class="d-md-none">
            <MobileCheckIcon />
          </div>
          <div class="partner-why-text">
            <h3 class="light-theme-title">
              {{ $t("partner.why-partner.report") }}
            </h3>
            <p
              style="max-width: 380px"
              class="body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
            >
              {{ $t("partner.why-partner.report-text") }}
            </p>
          </div>
        </div>
      </div>
      <div class="d-none d-md-block">
        <PartnerWhyIcon class="partner-why-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import PartnerWhyIcon from "../svg/why-partner/PartnerWhyIcon.vue";
import PartnerWhyCheckIcon from "../svg/why-partner/PartnerWhyCheckIcon.vue";
import MobileCheckIcon from "../svg/why-partner/MobileCheckIcon.vue";
export default {
  components: { PartnerWhyIcon, PartnerWhyCheckIcon, MobileCheckIcon },
};
</script>

<style lang="scss" scoped>
.partner-why {
  @media (max-width: 767px) {
    margin-top: 122px !important;
  }
  @media (min-width: 768px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 166px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 160px !important;
  }
  .partner-why-items {
    @media (max-width: 767px) {
      gap: 40px !important;
    }
    @media (min-width: 768px) {
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      gap: 32px !important;
    }
    @media (min-width: 1200px) {
      gap: 40px !important;
    }
    .partner-why-item {
      @media screen and (max-width: 768px) {
        gap: 10px !important;
      }
    }
  }
}
p {
  margin: 0 !important;
}
.partner-why-content {
  @media (max-width: 767px) {
    margin-bottom: 114px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 87px;
  }
  @media (min-width: 1200px) {
    margin: auto auto 171px auto;
    max-width: 1032px !important;
  }
}
.partner-why-icon {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    width: 390px;
  }
  @media (min-width: 1200px) {
    width: 507px;
  }
}

h3 {
  @media (max-width: 767px) {
    margin-bottom: 9px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 8px !important;
    font-size: 28px !important;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}

h2 {
  @media (max-width: 767px) {
    margin-bottom: 39px !important;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 73px !important;
  }
  @media (min-width: 1200px) {
    margin-bottom: 64px !important;
  }
}
.partner-why-text {
  @media (min-width: 768px) {
    margin-left: 18px !important;
  }
}
</style>
