var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "588",
      "height": "409",
      "viewBox": "0 0 588 409",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M384.876 255.966H100.156V59.6602H111.485H384.876V255.966Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M400.458 273.743H115.738V77.4375H127.067H400.458V273.743Z",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.19567",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M344.728 173.297L317.452 143.432L290.137 173.297V93.2461H344.728V173.297Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M356.574 64.5675C356.574 86.3924 338.929 104.098 317.179 104.098C307.43 104.098 298.548 100.573 291.64 94.6709C283.154 87.4223 277.785 76.6089 277.785 64.5675C277.785 42.7426 295.43 25.0371 317.179 25.0371C338.929 25.0371 356.574 42.7426 356.574 64.5675Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "132.211",
      "y": "110.867",
      "width": "133.687",
      "height": "9.54905",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "132.211",
      "y": "139.512",
      "width": "133.687",
      "height": "9.54905",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "132.211",
      "y": "168.16",
      "width": "90.716",
      "height": "9.54905",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "132.211",
      "y": "196.809",
      "width": "66.8434",
      "height": "9.54905",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M582.24 301.422H449.848C449.848 284.402 461.1 269.926 476.768 264.631C480.685 247.113 496.751 233.992 515.98 233.992C535.18 233.992 551.232 247.085 555.163 264.575C570.916 269.829 582.24 284.347 582.24 301.422Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M588.002 275.93H526.328C526.328 267.835 531.57 260.95 538.869 258.431C540.693 250.1 548.178 243.859 557.135 243.859C566.079 243.859 573.557 250.087 575.388 258.405C582.727 260.904 588.002 267.808 588.002 275.93Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M341.352 397.332H372.084",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M352.332 403.918H436.847",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M412.516 355.891C397.997 342.927 362.109 321.946 334.712 341.727C300.466 366.453 250.532 360.169 250.788 310.876C251.044 261.582 372.253 238.005 334.658 147.566C297.063 57.1272 186.062 101.055 199.432 201.914",
      "stroke": "#ED6400",
      "stroke-width": "1.19567",
      "stroke-linecap": "round",
      "stroke-dasharray": "2.39 2.39"
    }
  }), _c('rect', {
    attrs: {
      "x": "339.156",
      "y": "323.791",
      "width": "127.321",
      "height": "59.2701",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "349.629",
      "y": "332.07",
      "width": "126.125",
      "height": "58.0744",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.19567"
    }
  }), _c('circle', {
    attrs: {
      "cx": "412.692",
      "cy": "360.56",
      "r": "17.0127",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M364.402 361.107H388.549",
      "stroke": "#ED6400",
      "stroke-width": "1.79351",
      "stroke-linecap": "round",
      "stroke-dasharray": "5.98 5.98"
    }
  }), _c('path', {
    attrs: {
      "d": "M437.395 361.107H461.542",
      "stroke": "#ED6400",
      "stroke-width": "1.79351",
      "stroke-linecap": "round",
      "stroke-dasharray": "5.98 5.98"
    }
  }), _c('circle', {
    attrs: {
      "cx": "264.288",
      "cy": "340.023",
      "r": "41.9758",
      "stroke": "#ED6400",
      "stroke-width": "1.19567"
    }
  }), _c('path', {
    attrs: {
      "d": "M291.941 371.777L311.129 390.964",
      "stroke": "#ED6400",
      "stroke-width": "1.19567",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M306.797 386.629L325.984 405.816",
      "stroke": "#ED6400",
      "stroke-width": "5.97837",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M250.594 344.578L262.762 356.746L287.097 332.41",
      "stroke": "#07B53B",
      "stroke-width": "1.79351",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M169 289.586H332.542",
      "stroke": "#07B53B",
      "stroke-width": "1.79351",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M174.484 283H250.218",
      "stroke": "#07B53B",
      "stroke-width": "1.79351",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M190.982 343.55H14.2695C14.2695 320.832 29.2887 301.51 50.2014 294.442C55.4296 271.06 76.8747 253.547 102.54 253.547C128.168 253.547 149.594 271.023 154.841 294.368C175.868 301.381 190.982 320.758 190.982 343.55Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M79.0268 318.304H0C0 308.054 6.71665 299.335 16.0689 296.146C18.407 285.596 27.9973 277.693 39.4751 277.693C50.9359 277.693 60.5178 285.579 62.8643 296.113C72.2676 299.277 79.0268 308.02 79.0268 318.304Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "397.328",
      "cy": "300.742",
      "r": "10.3781",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.19567"
    }
  }), _c('circle', {
    attrs: {
      "cx": "514.228",
      "cy": "267.263",
      "r": "5.43893",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.19567"
    }
  }), _c('circle', {
    attrs: {
      "cx": "499.402",
      "cy": "227.201",
      "r": "10.3781",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.19567"
    }
  }), _c('circle', {
    attrs: {
      "cx": "268.91",
      "cy": "10.9759",
      "r": "10.3781",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.19567"
    }
  }), _c('path', {
    attrs: {
      "d": "M492.969 107.256L484.82 115.405",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M484.82 107.256L492.97 115.405",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M479.797 277.379L471.648 285.528",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M471.648 277.379L479.798 285.528",
      "stroke": "#07B53B",
      "stroke-width": "1.19567",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }