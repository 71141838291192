var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "46",
      "height": "47",
      "viewBox": "0 0 46 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M23 25.1663C15.5902 25.1663 9.58333 31.1732 9.58333 38.583V42.4163C9.58333 43.4749 8.72521 44.333 7.66667 44.333C6.60812 44.333 5.75 43.4749 5.75 42.4163V38.583C5.75 29.0561 13.4731 21.333 23 21.333C32.5269 21.333 40.25 29.0561 40.25 38.583V42.4163C40.25 43.4749 39.3919 44.333 38.3333 44.333C37.2748 44.333 36.4167 43.4749 36.4167 42.4163V38.583C36.4167 31.1732 30.4098 25.1663 23 25.1663Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M23 21.3337C27.2342 21.3337 30.6667 17.9012 30.6667 13.667C30.6667 9.43281 27.2342 6.00033 23 6.00033C18.7658 6.00033 15.3333 9.43281 15.3333 13.667C15.3333 17.9012 18.7658 21.3337 23 21.3337ZM23 25.167C29.3513 25.167 34.5 20.0183 34.5 13.667C34.5 7.31572 29.3513 2.16699 23 2.16699C16.6487 2.16699 11.5 7.31572 11.5 13.667C11.5 20.0183 16.6487 25.167 23 25.167Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }