var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Hero'), _c('WhyPartner'), _c('JoinPartner'), _c('AreYouReady', {
    staticClass: "a"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }