var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "402",
      "height": "276",
      "viewBox": "0 0 402 276",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M262.569 174.614H68.3398V40.6992H76.0681H262.569V174.614Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M273.19 186.741H78.9609V52.8262H86.6892H273.19V186.741Z",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.705604",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M235.175 118.22L216.568 97.8466L197.934 118.22V63.6113H235.175V118.22Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M243.255 44.0468C243.255 58.9352 231.219 71.0134 216.382 71.0134C209.73 71.0134 203.672 68.6086 198.959 64.5825C193.17 59.6377 189.508 52.2611 189.508 44.0468C189.508 29.1583 201.544 17.0801 216.382 17.0801C231.219 17.0801 243.255 29.1583 243.255 44.0468Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "90.2031",
      "y": "75.6309",
      "width": "91.1978",
      "height": "6.51413",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "90.2031",
      "y": "95.1699",
      "width": "91.1978",
      "height": "6.51413",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "90.2031",
      "y": "114.717",
      "width": "61.8842",
      "height": "6.51413",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "90.2031",
      "y": "134.258",
      "width": "45.5989",
      "height": "6.51413",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M397.201 205.624H306.887C306.887 194.013 314.563 184.138 325.251 180.526C327.923 168.576 338.883 159.625 352 159.625C365.098 159.625 376.049 168.557 378.73 180.488C389.477 184.072 397.201 193.976 397.201 205.624Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M401.127 188.235H359.055C359.055 182.713 362.63 178.016 367.609 176.298C368.854 170.614 373.96 166.357 380.07 166.357C386.172 166.357 391.273 170.606 392.522 176.28C397.528 177.985 401.127 182.695 401.127 188.235Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M232.871 271.049H253.836",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M240.363 275.545H298.017",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M241.762 221.602C231.857 212.758 207.376 198.445 188.686 211.939C165.324 228.807 131.26 224.52 131.435 190.894C131.61 157.267 214.295 141.183 188.649 79.4876C163.003 17.7925 87.2807 47.7591 96.4013 116.562",
      "stroke": "#ED6400",
      "stroke-width": "0.705604",
      "stroke-linecap": "round",
      "stroke-dasharray": "1.41 1.41"
    }
  }), _c('rect', {
    attrs: {
      "x": "231.371",
      "y": "220.881",
      "width": "86.8552",
      "height": "40.4326",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "238.458",
      "y": "226.476",
      "width": "86.1496",
      "height": "39.727",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.705604"
    }
  }), _c('circle', {
    attrs: {
      "cx": "281.531",
      "cy": "245.967",
      "r": "11.6057",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M248.594 246.338H265.066",
      "stroke": "#ED6400",
      "stroke-width": "1.05841",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.53 3.53"
    }
  }), _c('path', {
    attrs: {
      "d": "M298.383 246.338H314.855",
      "stroke": "#ED6400",
      "stroke-width": "1.05841",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.53 3.53"
    }
  }), _c('circle', {
    attrs: {
      "cx": "119.09",
      "cy": "168.205",
      "r": "28.6899",
      "stroke": "#ED6400",
      "stroke-width": "0.705604"
    }
  }), _c('path', {
    attrs: {
      "d": "M137.953 189.867L151.042 202.956",
      "stroke": "#ED6400",
      "stroke-width": "0.705604",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M148.09 199.996L161.179 213.085",
      "stroke": "#ED6400",
      "stroke-width": "3.52802",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M109.75 171.314L118.051 179.615L134.652 163.014",
      "stroke": "#07B53B",
      "stroke-width": "1.05841",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M115.289 197.551H226.853",
      "stroke": "#07B53B",
      "stroke-width": "1.05841",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M119.027 193.057H170.691",
      "stroke": "#07B53B",
      "stroke-width": "1.05841",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M130.287 234.361H9.73828C9.73828 218.863 19.984 205.682 34.2501 200.861C37.8167 184.91 52.446 172.963 69.9544 172.963C87.437 172.963 102.053 184.885 105.633 200.81C119.977 205.594 130.287 218.813 130.287 234.361Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M53.9101 217.139H0C0 210.147 4.58193 204.199 10.9618 202.024C12.5568 194.826 19.0991 189.436 26.929 189.436C34.7472 189.436 41.2837 194.815 42.8845 202.001C49.2992 204.159 53.9101 210.124 53.9101 217.139Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "271.046",
      "cy": "205.157",
      "r": "7.13472",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.705604"
    }
  }), _c('circle', {
    attrs: {
      "cx": "350.79",
      "cy": "182.319",
      "r": "3.76533",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.705604"
    }
  }), _c('circle', {
    attrs: {
      "cx": "340.675",
      "cy": "154.991",
      "r": "7.13472",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.705604"
    }
  }), _c('circle', {
    attrs: {
      "cx": "183.441",
      "cy": "7.48752",
      "r": "7.13472",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.705604"
    }
  }), _c('path', {
    attrs: {
      "d": "M336.281 73.166L330.722 78.7252",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M330.727 73.166L336.286 78.7252",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M327.301 189.219L321.742 194.778",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M321.746 189.219L327.305 194.778",
      "stroke": "#07B53B",
      "stroke-width": "0.705604",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }