<template>
  <div class="hero-custom">
    <div class="hero-custom__content mx-auto">
      <h1 class="hero-custom__title">
        {{ $t("partner.hero.header") }}&nbsp;<span class="text-primary">{{
          $t("partner.hero.header2")
        }}</span>
      </h1>
      <h2 class="hero-custom__text hero-subtitle text-grey-primary-main-500">
        {{ $t("partner.hero.sub-header1") }}
        <span class="text-primary"> {{ $t("partner.hero.p1") }}</span
        >, <span class="text-primary"> {{ $t("partner.hero.p2") }}</span
        >,
        {{ $t("partner.hero.sub-header2") }}
        <span class="text-primary"> {{ $t("partner.hero.p3") }}</span>
        {{ $t("partner.hero.sub-header3") }}
      </h2>

      <div class="hero-buttons d-flex">
        <b-button
          variant="primary"
          class="round-btn-16-24"
          style="width: 160px"
        >
          <a class="button-medium text-white-button" href="https://nozax.kb.help/how-can-i-register-as-an-ib-for-nozax" target="_blank">
            {{ $t("partner.hero.learn more") }}
          </a>
        </b-button>
      </div>
    </div>

    <div class="d-none d-xl-block">
      <HomeHero />
    </div>
    <div class="d-none d-md-block d-xl-none">
      <HomeTabletHero />
    </div>

    <div class="d-block d-md-none">
      <HomeMobileHero />
    </div>
  </div>
</template>

<script>
import HomeHero from "../svg/hero/HomeHero.vue";
import HomeMobileHero from "../svg/hero/HomeMobileHero.vue";
import HomeTabletHero from "../svg/hero/HomeTabletHero.vue";
export default {
  methods: {
  },
  components: {
    HomeHero,
    HomeMobileHero,
    HomeTabletHero,
  },
};
</script>

<style lang="scss" scoped>
.hero {
  @media (min-width: 768px) {
    padding-top: 23px;
    padding-bottom: 112px;
    .hero-content {
      top: 29% !important;
    }
  }
}
h1 {
  @media (max-width: 767px) {
    max-width: 305px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
h4 {
  @media (max-width: 767px) {
    max-width: 305px !important;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    max-width: 400px !important;
  }
  @media (min-width: 1200px) {
  }
}
.round-btn-16-24 {
  @media (max-width: 767px) {
    margin-top: 36px;
    margin-bottom: 56px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    margin-top: 24px;
    width: 200px !important;
    height: 48px !important;
  }
}
.hero-custom__text {
  width: 571px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 375px;
  }
  @media (max-width: 991px) {
    width: 300px;
  }
}
</style>
