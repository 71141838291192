var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_499_48626)"
    }
  }, [_c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.89062 23.7903L17.7239 34.6235L39.3904 12.957",
      "stroke": "#07B53B",
      "stroke-width": "5.22397",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_499_48626"
    }
  }, [_c('rect', {
    attrs: {
      "width": "41.5275",
      "height": "41.5275",
      "fill": "white",
      "transform": "translate(0.570312 0.773438)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }