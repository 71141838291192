var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "320",
      "height": "223",
      "viewBox": "0 0 320 223",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M209.46 139.299H54.5117V32.4668H60.677H209.46V139.299Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M217.937 148.973H62.9883V42.1406H69.1536H217.937V148.973Z",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.650704",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M189.915 68.4195L180.831 58.4732L171.734 68.4195V41.7598H189.915V68.4195Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M193.86 32.2099C193.86 39.4783 187.984 45.3748 180.741 45.3748C177.494 45.3748 174.536 44.2008 172.235 42.2353C169.409 39.8213 167.621 36.2201 167.621 32.2099C167.621 24.9415 173.497 19.0449 180.741 19.0449C187.984 19.0449 193.86 24.9415 193.86 32.2099Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "71.9531",
      "y": "60.334",
      "width": "72.7543",
      "height": "5.19673",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "71.9531",
      "y": "75.9238",
      "width": "72.7543",
      "height": "5.19673",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "71.9531",
      "y": "91.5156",
      "width": "49.369",
      "height": "5.19673",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "71.9531",
      "y": "107.105",
      "width": "36.3771",
      "height": "5.19673",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M316.862 164.038H244.812C244.812 154.776 250.936 146.898 259.463 144.016C261.594 134.482 270.338 127.342 280.803 127.342C291.252 127.342 299.987 134.467 302.127 143.986C310.7 146.845 316.862 154.745 316.862 164.038Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M319.997 150.164H286.434C286.434 145.759 289.286 142.012 293.258 140.641C294.251 136.107 298.324 132.711 303.199 132.711C308.067 132.711 312.136 136.1 313.133 140.627C317.126 141.987 319.997 145.744 319.997 150.164Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M185.77 216.234H202.495",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M191.742 219.818H237.736",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M224.496 193.682C216.595 186.627 197.064 175.209 182.154 185.974C163.517 199.43 136.342 196.01 136.482 169.184C136.621 142.358 202.584 129.526 182.125 80.3083C161.665 31.0902 101.257 54.9965 108.533 109.885",
      "stroke": "#ED6400",
      "stroke-width": "0.650704",
      "stroke-linecap": "round",
      "stroke-dasharray": "1.3 1.3"
    }
  }), _c('rect', {
    attrs: {
      "x": "184.57",
      "y": "176.213",
      "width": "69.2899",
      "height": "32.2557",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "190.271",
      "y": "180.716",
      "width": "68.6392",
      "height": "31.6049",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.650704"
    }
  }), _c('circle', {
    attrs: {
      "cx": "224.594",
      "cy": "196.221",
      "r": "9.25857",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M198.316 196.52H211.458",
      "stroke": "#ED6400",
      "stroke-width": "0.976055",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.25 3.25"
    }
  }), _c('path', {
    attrs: {
      "d": "M238.035 196.52H251.176",
      "stroke": "#ED6400",
      "stroke-width": "0.976055",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.25 3.25"
    }
  }), _c('circle', {
    attrs: {
      "cx": "143.833",
      "cy": "185.046",
      "r": "22.8438",
      "stroke": "#ED6400",
      "stroke-width": "0.650704"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.879 202.328L169.321 212.77",
      "stroke": "#ED6400",
      "stroke-width": "0.650704",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M166.969 210.41L177.411 220.852",
      "stroke": "#ED6400",
      "stroke-width": "3.25352",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.383 187.524L143.005 194.146L156.248 180.902",
      "stroke": "#07B53B",
      "stroke-width": "0.976055",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M91.9727 157.596H180.974",
      "stroke": "#07B53B",
      "stroke-width": "0.976055",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M94.957 154.012H136.173",
      "stroke": "#07B53B",
      "stroke-width": "0.976055",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M103.935 186.965H7.76562C7.76562 174.602 15.9393 164.087 27.3203 160.24C30.1655 147.515 41.8363 137.984 55.8039 137.984C69.7508 137.984 81.4112 147.495 84.2668 160.2C95.7099 164.016 103.935 174.562 103.935 186.965Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.0075 173.226H0C0 167.648 3.6553 162.903 8.74495 161.167C10.0174 155.426 15.2366 151.125 21.4829 151.125C27.7201 151.125 32.9347 155.417 34.2117 161.149C39.3291 162.871 43.0075 167.629 43.0075 173.226Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "216.231",
      "cy": "163.669",
      "r": "5.64792",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.650704"
    }
  }), _c('circle', {
    attrs: {
      "cx": "279.848",
      "cy": "145.447",
      "r": "2.95995",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.650704"
    }
  }), _c('circle', {
    attrs: {
      "cx": "271.782",
      "cy": "123.645",
      "r": "5.64792",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.650704"
    }
  }), _c('circle', {
    attrs: {
      "cx": "146.344",
      "cy": "5.97327",
      "r": "5.64792",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.650704"
    }
  }), _c('path', {
    attrs: {
      "d": "M268.281 58.3691L263.846 62.8041",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M263.844 58.3691L268.279 62.8041",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M261.117 150.953L256.682 155.388",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M256.68 150.953L261.115 155.388",
      "stroke": "#07B53B",
      "stroke-width": "0.650704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }