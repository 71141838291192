<template>
  <div class="partner-join section">
    <div>
      <h2 class="light-theme-title text-center">
        {{ $t("partner.join-partner.title") }}
      </h2>
      <h4
        class="partner-join-subheader text-center subtitle-bodyLarge text-grey-primary-main-500"
      >
        {{ $t("partner.join-partner.subtitle") }}
      </h4>
      <div class="partner-join-items container">
        <div class="row">
          <div
            class="partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
          >
            <div class="partner-join-icon">
              <PartnerRegisterIcon />
            </div>
            <div class="partner-join-content text-center">
              <h6 class="body-paragraph-bold-to-mobile-h3 light-theme-title">
                {{ $t("partner.join-partner.register") }}
              </h6>
              <p
                class="body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500"
                v-html="$t('partner.join-partner.register-text')"
              ></p>
            </div>
          </div>

          <div
            class="partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
          >
            <div class="partner-join-icon">
              <PartnerVerifyIcon />
            </div>
            <div class="partner-join-content text-center">
              <h6 class="body-paragraph-bold-to-mobile-h3 light-theme-title">
                {{ $t("partner.join-partner.verify") }}
              </h6>
              <p
                class="body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500"
              >
                {{ $t("partner.join-partner.verify-text") }}
              </p>
            </div>
          </div>

          <div
            class="partner-join-item d-flex flex-column start align-items-center col-12 col-md-6 col-xl-3"
          >
            <div class="partner-join-icon">
              <PartnerReferIcon />
            </div>
            <div class="partner-join-content text-center">
              <h6 class="body-paragraph-bold-to-mobile-h3 light-theme-title">
                {{ $t("partner.join-partner.refer") }}
              </h6>
              <p
                class="body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500 ref"
              >
                {{ $t("partner.join-partner.refer-text") }}
              </p>
            </div>
          </div>

          <div
            class="partner-join-item d-flex flex-column justify-content-start align-items-center col-12 col-md-6 col-xl-3"
          >
            <div class="partner-join-icon">
              <PartnerEarnIcon />
            </div>
            <div class="partner-join-content text-center">
              <h6 class="body-paragraph-bold-to-mobile-h3 light-theme-title">
                {{ $t("partner.join-partner.earn") }}
              </h6>
              <p
                class="body-small-text-to-mobile-body-regular light-theme-title-to-grey-primary-main-500 earn"
              >
                {{ $t("partner.join-partner.earn-text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerReferIcon from "../svg/join-partner/PartnerReferIcon.vue";
import PartnerRegisterIcon from "../svg/join-partner/PartnerRegisterIcon.vue";
import PartnerEarnIcon from "../svg/join-partner/PartnerVerifyIcon.vue";
import PartnerVerifyIcon from "../svg/join-partner/PartnerVerifyIcon.vue";
export default {
  components: {
    PartnerReferIcon,
    PartnerRegisterIcon,
    PartnerEarnIcon,
    PartnerVerifyIcon,
  },
};
</script>
<style lang="scss" scoped>
.partner-join {
  .partner-join-items {
    .partner-join-item {
      @media (max-width: 767px) {
        gap: 16px;
        margin-bottom: 48px;
      }

      @media (max-width: 1199px) and (min-width: 768px) {
        gap: 24px;
      }
      @media (min-width: 1200px) {
        gap: 24px;
        padding: 0 21px !important;
      }
      .partner-join-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #ffffff;
        filter: drop-shadow(0px 8px 16px rgba(218, 225, 233, 0.56));
        @media (max-width: 767px) {
          width: 88px;
          height: 88px;
        }
      }
      .partner-join-content {
        .partner-join-text {
          color: #616a70;
        }
      }
    }
  }
}
h2 {
  @media (max-width: 767px) {
    width: 80%;
    margin: auto;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
h4 {
  @media (max-width: 767px) {
    margin: 16px auto 40px auto !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin: 24px auto 21px auto !important;
  }
  @media (min-width: 1200px) {
    margin: 24px auto 56px auto !important;
  }
}
p {
  margin-top: 16px !important;
  @media (max-width: 767px) {
    max-width: 282px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
.row {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: 562px !important;
    margin: auto !important;
    row-gap: 58px;
  }
  @media (min-width: 1200px) {
  }
}
/* .section {
  @media (min-width: 768px) {
    padding: 0 120px 0 160px !important;
  }
} */
.container {
  @media (min-width: 768px) {
    padding: 0 !important;
  }
}
.ref {
  @media (min-width: 768px) {
    width: 196px !important;
  }
}
.earn {
  @media (min-width: 768px) {
    width: 253px !important;
  }
}

</style>
