var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "508",
      "height": "492",
      "viewBox": "0 0 508 492",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M60 327.346H6C6 320.518 10.5896 314.71 16.9801 312.585C18.5777 305.557 25.1309 300.293 32.9739 300.293C40.8052 300.293 47.3526 305.546 48.956 312.563C55.3814 314.671 60 320.495 60 327.346Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M437.887 98.341H391C391 92.4121 394.985 87.3693 400.534 85.5246C401.921 79.4223 407.611 74.8516 414.421 74.8516C421.22 74.8516 426.905 79.4127 428.298 85.5054C433.876 87.3356 437.887 92.3928 437.887 98.341Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M311.582 254.232L305.967 259.858",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M305.965 254.232L311.58 259.858",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M278.422 138.783L272.807 144.409",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.805 138.783L278.419 144.409",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.08203 248.17L3.46729 253.796",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.46484 248.17L9.07965 253.796",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M504.418 111.021L498.803 116.647",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M498.801 111.021L504.416 116.647",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M500.637 342.885L495.022 348.511",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M495.02 342.885L500.634 348.511",
      "stroke": "#07B53B",
      "stroke-width": "1.51247",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M203.531 4.10742L196.736 10.9163",
      "stroke": "#07B53B",
      "stroke-width": "1.83053",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M196.742 4.10742L203.538 10.9163",
      "stroke": "#07B53B",
      "stroke-width": "1.83053",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('path', {
    attrs: {
      "d": "M250.222 375.686C250.222 392.425 236.678 405.995 219.972 405.995C203.266 405.995 189.723 392.425 189.723 375.686C189.723 358.947 203.266 345.377 219.972 345.377C236.678 345.377 250.222 358.947 250.222 375.686Z",
      "fill": "#07B53B"
    }
  })]), _c('path', {
    attrs: {
      "d": "M164.011 105.938C164.011 122.677 150.467 136.247 133.761 136.247C117.055 136.247 103.512 122.677 103.512 105.938C103.512 89.1987 117.055 75.6289 133.761 75.6289C150.467 75.6289 164.011 89.1987 164.011 105.938Z",
      "fill": "#07B53B"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('path', {
    attrs: {
      "d": "M484.655 192.319C484.655 209.058 471.112 222.628 454.406 222.628C437.699 222.628 424.156 209.058 424.156 192.319C424.156 175.58 437.699 162.01 454.406 162.01C471.112 162.01 484.655 175.58 484.655 192.319Z",
      "fill": "#07B53B"
    }
  })])]), _c('path', {
    attrs: {
      "d": "M80.4219 222.495C80.4219 222.495 89.3632 183.873 107.246 174.914C125.128 165.956 143.011 165.956 160.894 174.914C178.776 183.873 187.718 222.495 187.718 222.495",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M121.66 107.831L130.735 116.924L148.885 98.7383",
      "stroke": "white",
      "stroke-width": "3.78118",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip0_499_48790)"
    }
  }, [_c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('path', {
    attrs: {
      "d": "M442.293 194.37L451.368 203.463L469.517 185.277",
      "stroke": "white",
      "stroke-width": "3.78118",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]), _c('path', {
    attrs: {
      "d": "M440.781 192.475L449.856 201.568L468.006 183.383",
      "stroke": "#FCFCFD",
      "stroke-width": "3.78118",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]), _c('path', {
    attrs: {
      "d": "M393.301 306.021C393.301 306.021 402.242 267.399 420.125 258.44C438.007 249.481 455.89 249.481 473.773 258.44C491.655 267.399 500.597 306.012 500.597 306.012",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M206.715 378.429L215.79 387.521L233.939 369.336",
      "stroke": "white",
      "stroke-width": "3.78118",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M160.832 491.331C160.832 491.331 169.773 452.709 187.656 443.75C205.539 434.792 223.421 434.792 241.304 443.75C259.187 452.709 268.128 491.322 268.128 491.322",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M416.188 134.12C378.465 89.5316 322.152 61.2305 259.25 61.2305C234.483 61.2305 210.752 65.6203 188.766 73.6565",
      "stroke": "#ED6400",
      "stroke-width": "4.53742",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-dasharray": "14.96 14.96"
    }
  }), _c('path', {
    attrs: {
      "d": "M298.883 469.514C372.318 455.144 431.769 401.633 454.703 331.529",
      "stroke": "#ED6400",
      "stroke-width": "4.53742",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-dasharray": "14.96 14.96"
    }
  }), _c('path', {
    attrs: {
      "d": "M54.3756 249.262C53.8568 255.256 53.5973 261.27 53.5977 267.287C53.5977 338.725 89.8815 401.671 144.996 438.644",
      "stroke": "#ED6400",
      "stroke-width": "4.53742",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-dasharray": "14.96 14.96"
    }
  }), _c('path', {
    attrs: {
      "d": "M447 434.825H354.195C354.195 422.871 362.083 412.703 373.066 408.984C375.811 396.68 387.074 387.465 400.553 387.465C414.011 387.465 425.264 396.661 428.02 408.945C439.062 412.635 447 422.832 447 434.825Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M429.136 114.253H352C352 104.308 358.556 95.849 367.684 92.7548C369.967 82.5186 379.328 74.8516 390.531 74.8516C401.717 74.8516 411.07 82.5024 413.36 92.7224C422.539 95.7925 429.136 104.275 429.136 114.253Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M109 336.364H20C20 324.983 27.5643 315.303 38.0968 311.763C40.73 300.049 51.5306 291.275 64.4569 291.275C77.3641 291.275 88.1551 300.031 90.7979 311.726C101.388 315.239 109 324.946 109 336.364Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M398.49 420.925H342C342 413.636 346.801 407.436 353.486 405.168C355.158 397.666 362.013 392.047 370.218 392.047C378.41 392.047 385.259 397.654 386.937 405.145C393.658 407.395 398.49 413.612 398.49 420.925Z",
      "fill": "#ED6400"
    }
  }), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_499_48790"
    }
  }, [_c('rect', {
    attrs: {
      "width": "34.7869",
      "height": "34.8552",
      "fill": "white",
      "transform": "translate(437 175.049)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }