<script>
import AreYouReady from "@/components/are-you-ready/AreYouReady.vue";
import Hero from "./components/sections/Hero.vue";
import JoinPartner from "./components/sections/JoinPartner.vue";
import WhyPartner from "./components/sections/WhyPartner.vue";
export default {
  components: {
    AreYouReady,
    Hero,
    JoinPartner,
    WhyPartner,
  },
  metaInfo: {
    title: 'NOZAX IB Program: Get up to 30% of the spread',
    meta: [
      { name: 'description', content: 'Help us spread the word and get rewarded! Competitive remuneration. Daily payout. Live reporting. Marketing support. ' },
    ]
  }
};
</script>

<template>
  <div>
    <Hero />
    <WhyPartner />
    <JoinPartner />
    <AreYouReady class="a" />
  </div>
</template>

<style lang="scss" scoped>
.a {
  @media (max-width: 767px) {
    margin-top: 50px;
    margin-bottom: 65px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 73px;
    margin-bottom: 82px;
  }
  @media (min-width: 1200px) {
    margin-top: 132px;
    margin-bottom: 64px;
  }
}
</style>
