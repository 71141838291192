var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-custom"
  }, [_c('div', {
    staticClass: "hero-custom__content mx-auto"
  }, [_c('h1', {
    staticClass: "hero-custom__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.header")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.$t("partner.hero.header2")))])]), _c('h2', {
    staticClass: "hero-custom__text hero-subtitle text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.sub-header1")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.p1")))]), _vm._v(", "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.p2")))]), _vm._v(", " + _vm._s(_vm.$t("partner.hero.sub-header2")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.p3")))]), _vm._v(" " + _vm._s(_vm.$t("partner.hero.sub-header3")) + " ")]), _c('div', {
    staticClass: "hero-buttons d-flex"
  }, [_c('b-button', {
    staticClass: "round-btn-16-24",
    staticStyle: {
      "width": "160px"
    },
    attrs: {
      "variant": "primary"
    }
  }, [_c('a', {
    staticClass: "button-medium text-white-button",
    attrs: {
      "href": "https://nozax.kb.help/how-can-i-register-as-an-ib-for-nozax",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.hero.learn more")) + " ")])])], 1)]), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('HomeHero')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none"
  }, [_c('HomeTabletHero')], 1), _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('HomeMobileHero')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }