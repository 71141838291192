var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.3672 22.6943C17.89 22.6943 22.3672 18.2172 22.3672 12.6943C22.3672 7.17149 17.89 2.69434 12.3672 2.69434C6.84434 2.69434 2.36719 7.17149 2.36719 12.6943C2.36719 18.2172 6.84434 22.6943 12.3672 22.6943ZM18.0743 10.4014C18.4648 10.0109 18.4648 9.37775 18.0743 8.98723C17.6838 8.5967 17.0506 8.5967 16.6601 8.98723L11.3672 14.2801L9.07429 11.9872C8.68377 11.5967 8.0506 11.5967 7.66008 11.9872C7.26956 12.3778 7.26956 13.0109 7.66008 13.4014L10.6601 16.4014C11.0506 16.792 11.6838 16.792 12.0743 16.4014L18.0743 10.4014Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }